import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useTranslations } from "@/i18n";
import type { CatalogLocals } from "@/types";
import { useStore } from "@nanostores/react";
import { $quickAdd, $quickAddOpen } from "../quick-add/quick-add.store";
import { useMediaQuery } from "@/hooks/use-media-query";
import { addProductToCart } from "@/features/cart/cart.store";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { formatCurrency } from "@/lib/format-currency";
import { cn } from "@/lib/utils";
import { toast } from "sonner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import type { CatalogProduct } from "../types";
import { ProductGroupOptions } from "../shared/ProductGroupOptions";
import { ImageWithFallback } from "@/components/ImageWithFallback";
import { SelectQuantity } from "@/features/shared/SelectQuantity";
import { usePreparedProduct } from "@/hooks/use-prepared-product";
import { SelectUnitType } from "@/features/shared/SelectUnitType";
import { formatDate } from "@/lib/format-date";
import { ProductPrice } from "../shared/ProductPrice";

type Props = { locals: CatalogLocals };

export function QuickAdd({ locals }: Props) {
  const isDesktop = useMediaQuery("(min-width: 480px)");

  const open = useStore($quickAddOpen);
  const product = useStore($quickAdd);

  const title = product?.name;

  if (isDesktop) {
    return (
      <Dialog open={!!open} onOpenChange={$quickAddOpen.set}>
        <DialogContent
          className="sm:max-w-screen-sm p-0"
          aria-describedby={undefined}
        >
          <VisuallyHidden>
            <DialogHeader>
              <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
          </VisuallyHidden>

          {product && <QuickAddContent product={product} locals={locals} />}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={!!open} onOpenChange={$quickAddOpen.set}>
      <DrawerContent aria-describedby={undefined}>
        <VisuallyHidden>
          <DrawerHeader className="text-left">
            <DrawerTitle>{title}</DrawerTitle>
          </DrawerHeader>
        </VisuallyHidden>

        {product && <QuickAddContent product={product} locals={locals} />}
      </DrawerContent>
    </Drawer>
  );
}

const QuickAddContent = ({
  product,
  locals: { lang, supplier },
}: {
  locals: CatalogLocals;
  product: CatalogProduct;
}) => {
  const t = useTranslations(lang);
  const preparedProduct = usePreparedProduct({ product });

  return (
    <div className="flex flex-col gap-2 sm:gap-4">
      <div>
        <div className={cn("grid grid-cols-1 gap-1 p-2 sm:p-4")}>
          <div className="grid grid-cols-[1fr_3fr] md:grid-cols-[1fr_4fr] gap-2">
            <ImageWithFallback
              src={product.image}
              alt={product.name}
              className="w-full aspect-square rounded-lg object-cover"
            />
            <div>
              <h2 className="text-one font-semibold text-lg">
                <a
                  href={`/${lang}/products/${product.slug}`}
                  onClick={() => $quickAddOpen.set(false)}
                >
                  {product.name}
                </a>
              </h2>

              <p className="text-sm text-two capitalize">
                {Object.entries(product.options)
                  .map(([k, v]) => `${k} ${v}`)
                  .join(" | ")}
              </p>
              <ProductPrice
                product={product}
                unitType={preparedProduct.unitType}
                lang={lang}
                country={supplier.countryCode}
              />
            </div>
          </div>

          <ProductGroupOptions
            product={product}
            lang={lang}
            onSelect={({ target }) => target && $quickAdd.set(target)}
            supplier={supplier}
            className="my-2"
          />

          <Table className="[&_th]:h-auto [&_td]:p-2">
            <TableBody>
              {product.details.unitPrice && (
                <TableRow>
                  <TableHead>{t("products.fields.unitPrice")}</TableHead>
                  <TableCell>{product.details.unitPrice}</TableCell>
                </TableRow>
              )}
              {product.details.upc && (
                <TableRow>
                  <TableHead>{t("products.fields.upc")}</TableHead>
                  <TableCell>{product.details.upc}</TableCell>
                </TableRow>
              )}
              {product.details.dueDate && (
                <TableRow>
                  <TableHead>{t("products.fields.dueDate")}</TableHead>
                  <TableCell>
                    {formatDate(product.details.dueDate, lang)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div className="flex gap-2 items-center">
            {t("common.quantity")}:
            <SelectQuantity
              value={preparedProduct.quantity}
              onValueChange={preparedProduct.setQuantity}
              max={preparedProduct.stock}
              decimals={product.partialUnitsAllowed}
              triggerClassName="w-20 flex gap-2"
              disabled={!preparedProduct.addable}
            />
            {preparedProduct.shouldShowUnitTypePicker && (
              <SelectUnitType
                value={preparedProduct.unitTypeName}
                onValueChange={preparedProduct.setUnitTypeViaName}
                triggerClassName="w-full"
                choices={product.unitTypes}
              />
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-[1fr_2fr] sm:grid-cols-2 gap-2 sm:gap-x-6 p-2 sm:p-4 sm:pt-0">
        <Button
          variant="secondary"
          size="lg"
          onClick={() => $quickAddOpen.set(false)}
        >
          {t("common.close")}
        </Button>

        <Button
          variant="one"
          size="lg"
          disabled={!preparedProduct.addable}
          onClick={() => {
            $quickAddOpen.set(false);
            const isCheckoutPage = location.pathname.endsWith("/checkout");
            addProductToCart(preparedProduct.addableItem, !isCheckoutPage);
            if (isCheckoutPage) {
              toast.info(`${product.name} ${t("common.added").toLowerCase()}`);
            }
          }}
        >
          {preparedProduct.stock <= 0
            ? t("products.filters.stock.no")
            : `${t("common.add")} - ${formatCurrency(preparedProduct.addableAmount, lang, supplier.countryCode)}`}
        </Button>
      </div>
    </div>
  );
};
